import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PageTitle from "../../Common/PageTitle";
import Spinner from "../../Common/Spinner";
import ConfigService from "../../../services/ConfigService";
import CommonUtiles from "../../../services/CommonUtiles";

const StatisticsData = () => {
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const history = useHistory();

  const activeUserTooltip = "Top 10 Active users as determined by their login count";

  // to get statistic list from API
  const getStatisticList = () => {
    setLoading(true);
    ConfigService.getStatisticData()
      .then((response) => {
        setLoading(false);
        setStatisticsData(response?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatisticList();
  }, []);

  // Redirect to Manage user page
  const redirectToUserList = (e, type) => {
    if (!loading) {
      history.push({ pathname: "/users", state: { usertype: type } });
    }
  };

  // keyDown handler for eslint error
  const onKeyDownHandler = (e, type) => {
    if (e.key === "Enter" || e.key === " ") {
      redirectToUserList(e, type);
    }
  };

  return (
    <>
      <PageTitle title="Statistics" class="" />
      <div className="row gutters-sm">
        <div className="col-xl-auto col-12 w-260">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div
                className="card p-5 h-150 curser-pointer"
                role="button"
                tabIndex={0}
                onClick={(e) => redirectToUserList(e, "active")}
                onKeyDown={(e) => onKeyDownHandler(e, "active")}
              >
                <h4 className="text-muted">Active Users</h4>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="display-3 text-success">
                    {statisticsData && statisticsData.activeUsers && statisticsData?.activeUsers}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-12 col-sm-6">
              <div
                role="button"
                className="card p-5 h-150 curser-pointer"
                tabIndex={0}
                onClick={(e) => redirectToUserList(e, "disabled")}
                onKeyDown={(e) => onKeyDownHandler(e, "disabled")}
              >
                <h4 className="text-muted">Disabled Users</h4>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="display-3 text-warning">
                    {statisticsData && statisticsData.disabledUsers && statisticsData?.disabledUsers}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header bg-gray-dark">
              <h4 className="mb-0">Departments</h4>
            </div>
            <div className="list-group list-group-flush card-body-scrollable h-260">
              {loading ? (
                <div className=" list-group flex-grow-1 d-flex justify-content-center">
                  <Spinner />
                </div>
              ) : statisticsData?.departments && statisticsData?.departments?.length ? (
                statisticsData?.departments
                  ?.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }))
                  ?.map((dep) => (
                    <div className="list-group-item list-group-item-action cell-mx-width">
                      <span className="text-truncate d-inline-block max-width-100" data-tip={dep}>
                        {dep}
                      </span>
                    </div>
                  ))
              ) : (
                <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                  <h3 className="text-muted mb-3">No Data Found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl">
          <div className="row gutters-sm">
            <div className="col-sm col-sm-250">
              <div className="card">
                <div className="card-header bg-gray-dark">
                  <h4 className="mb-0" data-tip={activeUserTooltip}>
                    Top 10 Active Users
                  </h4>
                </div>
                <div className="table-responsive">
                  <table className="table table-outline table-vcenter card-table">
                    <thead className="text-nowrap table-head">
                      <tr>
                        <th className="header-sticky w-8-res" data-tip="Rank">
                          Rank
                        </th>
                        <th className="header-sticky w-15-res" data-tip="Email Address">
                          Email Address
                        </th>
                        <th className="header-sticky w-12-res" data-tip="First Name">
                          First Name
                        </th>
                        <th className="header-sticky w-12-res" data-tip="Last Name">
                          Last Name
                        </th>
                        <th className="header-sticky w-10-res" data-tip="Company">
                          Company
                        </th>
                        <th className="header-sticky w-8-res" data-tip="Role">
                          Role
                        </th>
                        <th className="header-sticky w-15-res text-center" data-tip="Login Count">
                          Login Count
                        </th>
                        <th className="header-sticky w-15-res" data-tip="Last Login date">
                          Last Login date
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan="8" aria-label="td">
                            <Spinner />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {statisticsData && Object.keys(statisticsData)?.length ? (
                          statisticsData?.topUserBasedOnLoginCount?.map((user, index) => {
                            const convertDate = CommonUtiles.formatDate(user?.lastLoginTime);
                            return (
                              <tr>
                                <td>
                                  <div className="cell-mx-width">{index + 1}</div>
                                </td>
                                <td>
                                  <div className="cell-mx-width" data-tip={user?.email}>
                                    {user?.email}
                                  </div>
                                </td>
                                <td>
                                  <div className="cell-mx-width" data-tip={user?.name}>
                                    {user?.name}
                                  </div>
                                  <ReactTooltip place="bottom" type="light" effect="solid" />
                                </td>
                                <td>
                                  <div className="cell-mx-width" data-tip={user?.lastName}>
                                    {user?.lastName}
                                  </div>
                                </td>
                                <td>
                                  <div className="cell-mx-width" data-tip={user?.company}>
                                    {user?.company}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="cell-mx-width capitilize-column"
                                    data-tip={user?.role?.toLowerCase()?.replace(/^\w/, (c) => c.toUpperCase())}
                                  >
                                    {user?.role}
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="cell-mx-width">{user?.loginCount}</div>
                                </td>
                                <td className="text-center">
                                  <div className="cell-mx-width" data-tip={convertDate}>
                                    {convertDate}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="8">
                              <div className="text-center py-5">
                                <div className="mb-5">
                                  <i className="fa fa-2x fa-user text-primary" />
                                </div>
                                <h3 className="text-muted mb-3">No Data Found</h3>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="col-sm-auto w-250">
              <div className="card">
                <div className="card-header bg-gray-dark">
                  <h4 className="mb-0">Top 10 Departments</h4>
                </div>
                <div className="table-responsive">
                  <ReactTooltip place="bottom" type="light" effect="solid" />
                  <table className="table table-outline table-vcenter card-table">
                    <thead className="text-nowrap table-head">
                      <tr>
                        <th className="header-sticky width-10" scope="col" data-tip="Departments">
                          Departments
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan="1" aria-label="td">
                            <Spinner />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {statisticsData && statisticsData?.topDepartmentsBasedOnLoginCount?.length ? (
                          statisticsData?.topDepartmentsBasedOnLoginCount?.map((dep) => (
                            <tr>
                              <td>
                                <div className="cell-mx-width" data-tip={dep}>
                                  {dep}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                            <h4 className="text-muted p-6 mb-0">No Data Found</h4>
                          </div>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatisticsData;
